import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _35349ae8 = () => interopDefault(import('../pages/theme-2/cashier.vue' /* webpackChunkName: "pages/theme-2/cashier" */))
const _4864942a = () => interopDefault(import('../pages/theme-2/forgot-password.vue' /* webpackChunkName: "pages/theme-2/forgot-password" */))
const _1746b5ae = () => interopDefault(import('../pages/theme-2/games/index.vue' /* webpackChunkName: "pages/theme-2/games/index" */))
const _78ebbbce = () => interopDefault(import('../pages/theme-2/login.vue' /* webpackChunkName: "pages/theme-2/login" */))
const _e6689aa8 = () => interopDefault(import('../pages/theme-2/my-bonus/index.vue' /* webpackChunkName: "pages/theme-2/my-bonus/index" */))
const _09c52aae = () => interopDefault(import('../pages/theme-2/no-support.vue' /* webpackChunkName: "pages/theme-2/no-support" */))
const _0a93226d = () => interopDefault(import('../pages/theme-2/not-found.vue' /* webpackChunkName: "pages/theme-2/not-found" */))
const _63593493 = () => interopDefault(import('../pages/theme-2/payment-methods/index.vue' /* webpackChunkName: "pages/theme-2/payment-methods/index" */))
const _3921e060 = () => interopDefault(import('../pages/theme-2/play-game.vue' /* webpackChunkName: "pages/theme-2/play-game" */))
const _968423ea = () => interopDefault(import('../pages/theme-2/promotion/index.vue' /* webpackChunkName: "pages/theme-2/promotion/index" */))
const _479bdcb9 = () => interopDefault(import('../pages/theme-2/refer-friend/index.vue' /* webpackChunkName: "pages/theme-2/refer-friend/index" */))
const _6ee5224e = () => interopDefault(import('../pages/theme-2/register.vue' /* webpackChunkName: "pages/theme-2/register" */))
const _75bf7b47 = () => interopDefault(import('../pages/theme-2/reset-password/index.vue' /* webpackChunkName: "pages/theme-2/reset-password/index" */))
const _6a218c44 = () => interopDefault(import('../pages/theme-2/third-party-payment.vue' /* webpackChunkName: "pages/theme-2/third-party-payment" */))
const _fcf63c90 = () => interopDefault(import('../pages/theme-2/history/game.vue' /* webpackChunkName: "pages/theme-2/history/game" */))
const _1b8b6af0 = () => interopDefault(import('../pages/theme-2/history/transaction.vue' /* webpackChunkName: "pages/theme-2/history/transaction" */))
const _5ab1bbb7 = () => interopDefault(import('../pages/theme-2/payment-methods/add-bank.vue' /* webpackChunkName: "pages/theme-2/payment-methods/add-bank" */))
const _475d591a = () => interopDefault(import('../pages/theme-2/payment-methods/add-crypto-wallet.vue' /* webpackChunkName: "pages/theme-2/payment-methods/add-crypto-wallet" */))
const _0190a6aa = () => interopDefault(import('../pages/theme-2/player/deposits/index.vue' /* webpackChunkName: "pages/theme-2/player/deposits/index" */))
const _7b1610bc = () => interopDefault(import('../pages/theme-2/player/messages/index.vue' /* webpackChunkName: "pages/theme-2/player/messages/index" */))
const _1cee8434 = () => interopDefault(import('../pages/theme-2/player/profile.vue' /* webpackChunkName: "pages/theme-2/player/profile" */))
const _96b41044 = () => interopDefault(import('../pages/theme-2/player/withdrawals/index.vue' /* webpackChunkName: "pages/theme-2/player/withdrawals/index" */))
const _3ac576d8 = () => interopDefault(import('../pages/theme-2/promotion/bonus.vue' /* webpackChunkName: "pages/theme-2/promotion/bonus" */))
const _a177ce92 = () => interopDefault(import('../pages/theme-2/index.vue' /* webpackChunkName: "pages/theme-2/index" */))
const _7d393b8d = () => interopDefault(import('../pages/theme-2/payment-methods/edit/bank/_id.vue' /* webpackChunkName: "pages/theme-2/payment-methods/edit/bank/_id" */))
const _10ba74dc = () => interopDefault(import('../pages/theme-2/payment-methods/edit/crypto/_id.vue' /* webpackChunkName: "pages/theme-2/payment-methods/edit/crypto/_id" */))
const _4a75f733 = () => interopDefault(import('../pages/theme-2/promotion/_id.vue' /* webpackChunkName: "pages/theme-2/promotion/_id" */))
const _6d357dcb = () => interopDefault(import('../pages/theme-2/articles/_title/_slug.vue' /* webpackChunkName: "pages/theme-2/articles/_title/_slug" */))
const _23a5c9ae = () => interopDefault(import('../pages/theme-2/_type/_id.vue' /* webpackChunkName: "pages/theme-2/_type/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cashier",
    component: _35349ae8,
    name: "cashier"
  }, {
    path: "/forgot-password",
    component: _4864942a,
    name: "forgot-password"
  }, {
    path: "/games",
    component: _1746b5ae,
    name: "games"
  }, {
    path: "/login",
    component: _78ebbbce,
    name: "login"
  }, {
    path: "/my-bonus",
    component: _e6689aa8,
    name: "my-bonus"
  }, {
    path: "/no-support",
    component: _09c52aae,
    name: "no-support"
  }, {
    path: "/not-found",
    component: _0a93226d,
    name: "not-found"
  }, {
    path: "/payment-methods",
    component: _63593493,
    name: "payment-methods"
  }, {
    path: "/play-game",
    component: _3921e060,
    name: "play-game"
  }, {
    path: "/promotion",
    component: _968423ea,
    name: "promotion"
  }, {
    path: "/refer-friend",
    component: _479bdcb9,
    name: "refer-friend"
  }, {
    path: "/register",
    component: _6ee5224e,
    name: "register"
  }, {
    path: "/reset-password",
    component: _75bf7b47,
    name: "reset-password"
  }, {
    path: "/third-party-payment",
    component: _6a218c44,
    name: "third-party-payment"
  }, {
    path: "/history/game",
    component: _fcf63c90,
    name: "history-game"
  }, {
    path: "/history/transaction",
    component: _1b8b6af0,
    name: "history-transaction"
  }, {
    path: "/payment-methods/add-bank",
    component: _5ab1bbb7,
    name: "payment-methods-add-bank"
  }, {
    path: "/payment-methods/add-crypto-wallet",
    component: _475d591a,
    name: "payment-methods-add-crypto-wallet"
  }, {
    path: "/player/deposits",
    component: _0190a6aa,
    name: "player-deposits"
  }, {
    path: "/player/messages",
    component: _7b1610bc,
    name: "player-messages"
  }, {
    path: "/player/profile",
    component: _1cee8434,
    name: "player-profile"
  }, {
    path: "/player/withdrawals",
    component: _96b41044,
    name: "player-withdrawals"
  }, {
    path: "/promotion/bonus",
    component: _3ac576d8,
    name: "promotion-bonus"
  }, {
    path: "/",
    component: _a177ce92,
    name: "index"
  }, {
    path: "/payment-methods/edit/bank/:id?",
    component: _7d393b8d,
    name: "payment-methods-edit-bank-id"
  }, {
    path: "/payment-methods/edit/crypto/:id?",
    component: _10ba74dc,
    name: "payment-methods-edit-crypto-id"
  }, {
    path: "/promotion/:id",
    component: _4a75f733,
    name: "promotion-id"
  }, {
    path: "/articles/:title?/:slug?",
    component: _6d357dcb,
    name: "articles-title-slug"
  }, {
    path: "/:type/:id?",
    component: _23a5c9ae,
    name: "type-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
